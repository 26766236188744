.dashboard {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
}

.stats-block {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 20px;
    flex-wrap: wrap; /* Allows the blocks to wrap on smaller screens */
}

.stat {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    width: 31%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
}

.stat h3 {
    margin-bottom: 10px;
    font-size: 18px;
    color: #333;
}

.stat p {
    font-size: 24px;
    font-weight: bold;
    color: #007bff;
}

.links-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.links-table th,
.links-table td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: left;
}

.links-table th {
    background-color: #007bff;
    color: #fff;
    font-weight: normal;
}

.links-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.links-table .s-no {
    width: 8%;
}

.links-table .s-views {
    width: 50px;
}

.links-table .action {
    width: 20%;
}

.links-table td button {
    margin-right: 10px;
    padding: 5px 10px;
    background-color: #6a7279;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.links-table td button:hover {
    background-color: #5a5f64;
}

.links-table td .btn-for-share {
    background-color: #545b62;
}

.links-table td .btn-for-share:hover {
    background-color: #494f55;
}

/* Style for the select dropdown */
.links-table select {
    padding: 5px 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    color: #333;
    cursor: pointer;
}

.links-table select:focus {
    border-color: #007bff;
    outline: none;
}

.links-table select option {
    padding: 10px;
}

.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pagination button {
    padding: 10px 15px;
    background-color: #48a0ff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.pagination button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.pagination button:hover:not(:disabled) {
    background-color: #0056b3;
}

.button-like {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
}

.button-like:hover {
    background-color: #0056b3;
}  

/* Responsive Styles */
@media (max-width: 768px) {
    .stat {
        width: 100%; /* Make stats block take full width on smaller screens */
    }

    .links-table th,
    .links-table td {
        padding: 8px; /* Reduce padding for better fit on smaller screens */
        font-size: 14px; /* Adjust font size for better readability */
    }

    .pagination button {
        width: 40%; /* Make pagination buttons full width on smaller screens */
        margin-bottom: 10px; /* Add space between buttons */
    }
}

@media (max-width: 480px) {
    .dashboard {
        padding: 10px; /* Reduce padding for very small screens */
    }

    .stat p {
        font-size: 20px; /* Adjust stat number font size for better fit */
    }

    .links-table th,
    .links-table td {
        padding: 6px; /* Further reduce padding on very small screens */
        font-size: 12px; /* Smaller font size for table content */
    }
}
