.aboutContainer {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.heading {
    text-align: center;
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
}

.subheading {
    font-size: 1.5rem;
    color: #007BFF;
    margin-top: 20px;
    margin-bottom: 10px;
}

.text {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
    margin-bottom: 20px;
}

.list {
    margin: 10px 0;
    padding-left: 20px;
}

.list li {
    margin-bottom: 10px;
    color: #555;
    font-size: 1rem;
}

/* Responsive styles */

/* For tablets and smaller desktops */
@media (max-width: 768px) {
    .aboutContainer {
        padding: 15px;
        max-width: 100%;
    }

    .heading {
        font-size: 1.75rem;
    }

    .subheading {
        font-size: 1.4rem;
    }

    .text {
        font-size: 0.95rem;
    }

    .list li {
        font-size: 0.95rem;
    }
}

/* For mobile devices */
@media (max-width: 480px) {
    .aboutContainer {
        padding: 10px;
        max-width: 100%;
    }

    .heading {
        font-size: 1.5rem;
    }

    .subheading {
        font-size: 1.25rem;
    }

    .text {
        font-size: 0.9rem;
    }

    .list li {
        font-size: 0.9rem;
    }
}
