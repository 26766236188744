/* ReferAndEarn.module.css */
.referAndEarn {
    text-align: center;
    margin: 20px;
}

.referAndEarn h1 {
    font-size: 2em;
    margin-bottom: 10px;
}

.referAndEarn p {
    font-size: 1.2em;
    margin: 10px 0;
}

.referAndEarn a {
    text-decoration: none;
}

.referAndEarn a:hover {
    text-decoration: underline;
}

.referAndEarn button {
    font-size: 1em;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.referAndEarn button:hover {
    background-color: #0056b3;
}
