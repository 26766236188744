body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar-nav .nav-item .nav-link {
  white-space: nowrap;
}

.navbar-nav .nav-item .nav-link.active {
  font-weight: bold;
}

.hide-on-desktop {
  display: none;
  background-color: #5a6268;
  border-color: #545b62;
}

.hide-on-desktop:hover {
  background-color: #545b62;
  border-color: #4e555b;
}

.carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px; /* Optional: Add space at the top */
}

.carousel-container .carousel {
  width: 80%; /* Resize to 80% of the screen width */
}

@media only screen and (max-width: 768px) {
  .hide-on-desktop {
    display: inline-block;
  }

  .carousel-container .carousel {
    width: 100%; /* Full width on mobile */
  }
}