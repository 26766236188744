/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.loginMain {
  display: flex;
}

.loginLeft {
  flex-grow: 1;
  height: 95vh;
  background-color: #E9E9E9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginLeft img {
  width: 400px;
}

.loginRight {
  height: 92vh;
  flex-grow: 1;
}

.loginRightContainer {
  height: 100%;
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loginLogo {
  align-self: center;
  padding-top: 50px;
}

.loginCenter {
  margin: auto 0;
  text-align: center;
}

.loginLogo img {
  width: 50px;
}

.loginCenter h2 {
  font-size: 2.4rem;
}

.loginCenter p {
  font-weight: 400;
  font-size: 1.2rem;
  margin-bottom: 40px;
}

.formL input[type='email'],
.formL input[type='password'],
.formL input[type='text'] {
  width: 100%;
  padding: 16px;
  margin-bottom: 16px;
  border: 0;
  border-bottom: 1px solid black;
  outline: none;
  box-sizing: border-box;
}

.passInputDiv {
  position: relative;
}

.passInputDiv svg {
  font-size: 20px;
  position: absolute;
  right: 10px;
  bottom: 35px;
  cursor: pointer;
  outline: none;
}

.formL button[type='submit'] {
  width: 100%;
  padding: 16px;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  cursor: pointer;
  font-weight: 600;
}

.loginCenterOptions {
  display: flex;
  justify-content: space-between;
}

.rememberDiv {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.rememberDiv label {
  font-size: 1.1rem;
  font-weight: 500;
  cursor: pointer;
  margin-top: 2px;
}

.forgotPassLink {
  text-decoration: none;
  font-size: 1.1rem;
}

.forgotPassLink:hover {
  text-decoration: underline;
}

.loginCenterButtons {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.loginCenterButtons button:nth-child(1) {
  background-color: black;
  color: white;
  border: 3px solid black;
}

.loginCenterButtons button:nth-child(1):hover {
  color: #f0f0f0;
  background-color: #0056b3;
  border: 3px solid #0056b3;
}

.loginCenterButtons button:nth-child(2) {
  border: 3px solid #0056b3;
  background-color: #0056b3;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
}

.loginCenterButtons button:nth-child(2) img {
  width: 30px;
}

.loginCenterButtons button:nth-child(2):hover {
  background-color: #c4c4c457;
}

.loginBottomP {
  text-align: center;
  font-size: 1.2rem;
  padding-top: 10px;
  padding-bottom: 40px;
}

.loginBottomP a {
  text-decoration: none;
  font-weight: 600;
}

.loginBottomP a:hover {
  text-decoration: underline;
}

@media (max-width: 900px) {
  .loginLeft {
    display: none;
  }
}

@media (max-width: 370px) {
  .loginCenterOptions {
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
  }

  .loginCenterButtons {
    margin-top: 20px;
  }

  .loginCenter h2 {
    font-size: 3rem;
  }

  .loginCenter p {
    font-size: 1.5rem;
  }
}
