.forgetPasswordMain {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  padding: 20px;
}

.forgetPasswordContainer {
  background-color: #fff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.forgetPasswordHeader {
  margin-bottom: 20px;
  text-align: center;
}

.forgetPasswordPara {
  text-align: center;
  margin-bottom: 20px;
}

.forgetPasswordForm {
  display: flex;
  flex-direction: column;
}

.forgetPasswordInput {
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 16px;
}

.forgetPasswordButton {
  padding: 10px;
  background-color: black;
  color: #fff;
  border: 3px solid black;
  border-radius: 30px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
}

.forgetPasswordButton:hover {
  background-color: #0056b3;
  border: 3px solid #0056b3;
}

.forgetPasswordSuccessMessage {
  color: green;
  text-align: center;
  margin-top: 10px;
}

.forgetPasswordErrorMessage {
  color: red;
  text-align: center;
  margin-top: 10px;
}

.forgetPasswordLink {
  color: #007bff;
  text-decoration: none;
}

.forgetPasswordLink:hover {
  text-decoration: underline;
}
