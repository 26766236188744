.resetPasswordMain {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  padding: 20px;
  background-color: #f9f9f9;
}

.resetPasswordContainer {
  background-color: #fff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.resetPasswordHeader {
  margin-bottom: 20px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.resetPasswordForm {
  display: flex;
  flex-direction: column;
}

.passInputDiv {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.resetPasswordPassword {
  padding: 10px;
  padding-right: 40px; /* Adjust padding to make room for the eye icon */
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 16px;
  box-sizing: border-box;
}

.passInputDiv svg {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #666;
}

.resetPasswordButton {
  padding: 10px;
  background-color: #000;
  color: #fff;
  border: 3px solid #000;
  border-radius: 30px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.resetPasswordButton:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

.resetPasswordSuccessMessage,
.resetPasswordErrorMessage {
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
}

.resetPasswordSuccessMessage {
  color: green;
}

.resetPasswordErrorMessage {
  color: red;
}
